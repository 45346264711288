import Props from "./Props";

const ENV: Props = {
	API_URL: 'https://service.staging.ridevu.com',
	MASTER_API_KEY: '75-5-ab2ef',
	SENTRY_LOG_URL: 'https://57b844f9bd4649acb4af143f8dd52e34@o4504099501178880.ingest.sentry.io/4504099504848897',
	UNLEASH_PROXY_URL: 'https://unleash-proxy.staging.privilegemovies.com/proxy',
	UNLEASH_AUTH_KEY: 'Jjt8tXNa83t6i89o9XxNQbGhxvBRB2m4',
	MAINTENANCE_CONFIG_URL: 'https://cf.privilegemovies.com/remote-config/staging.json'
}

export default ENV;
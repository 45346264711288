import { spacing_xs, spacing_xxl } from "../../StyleHelpers";
import Image from "../Image/Image";
import iconChevron from "../../assets/Icons/icon_chevron-right.svg";
import styled from "styled-components/native";
import ImgProgressBar from "../Loaders/ImgProgressBar";
import TouchableOpacity from "../TouchableOpacity/TouchableOpacity";

interface Props {
	title?: any;
	noSpacing?: any;
	removeCursor?: boolean;
	isContinueWatching?: any;
	onPress?: () => void;
}

const StyledContainer = styled.View`
	flex-direction: row;
	align-items: center;
`;

const StyledText = styled.Text`
	flex-direction: row;
	align-items: center;
`;

const StyledLoader = styled.View`
	height: 24px;
	border-radius: 12px;
	overflow: hidden;
	width: 368px;
`;

const StyledTitle = styled.Text`
	font-size: 25px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
`;

const PlaylistTitle = (props: Props) => {
	const hasTitle = props.title.trim().length;

	return (
		// @ts-ignore
		<StyledText
			style={{
				fontSize: 25,
				color: "white",
				marginBottom: props.noSpacing ? 0 : spacing_xs,
				marginTop: props.noSpacing ? 0 : spacing_xxl,
			}}
		>
			{hasTitle && !props?.isContinueWatching ? (
				<TouchableOpacity onPress={props.onPress} style={props.removeCursor ? { cursor: "default" } : ""}>
					{/* @ts-ignore */}
					<StyledContainer>
						{/* @ts-ignore */}
						<StyledTitle>{props.title}</StyledTitle>
						<Image source={{ uri: iconChevron }} width={24} height={24} />
					</StyledContainer>
				</TouchableOpacity>
			) : <></>}

			{hasTitle && props?.isContinueWatching ? (
				//@ts-ignore
				<StyledContainer>
					{/* @ts-ignore */}
					<StyledTitle>{props.title}</StyledTitle>
				</StyledContainer>
			)  : <></>}

			{!props.title && (
				// @ts-ignore
				<StyledLoader>
					<ImgProgressBar></ImgProgressBar>
				</StyledLoader>
			)}
		</StyledText>
	);
};

export default PlaylistTitle;

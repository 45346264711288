import {
	ActivePlaybackChannel,
	AsyncStorageKeys,
	Device,
	GlobalType,
	MainAppState,
	Packshot,
	ProfileName,
	ProgressEvents,
	PurchaseTransactionType,
	Tabs,
} from "../Types";
import { Dimensions } from "react-native";
import { NativeStackNavigationProp } from "react-native-screens/native-stack";
import { routeTitleDetails, TitleDetailsScreenNavigationProp } from "../Routes";
import { INVALID_PASSWORD, INVALID_PROFILENAME, INVALID_PROFILENAME_OR_PASSWORD, INVALID_SHORTCODE } from "../networking/networking";
import { login as loginApi, logoutDevice, codeLogin as codeLoginApi } from "../services/loginService";
import { getMetadata } from "../services/metadataService";
import { EN } from "./Strings";
import { Client } from "mqtt";
// @ts-ignore
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AppLogger } from "./AppLogger";
import { getCategories } from "../services/categoriesService";
import { getPlaylist } from "../services/playlistService";
import { TransactionHistory } from "../models/TransactionHistory";
import { checkApiKey } from "../services/apiKeyService";
import { v4 as uuidv4 } from "uuid";
import { setProgressVideo } from "../services/progressVideoService";
import { DEFAULT_ENV } from "../config/ENV_CONFIG";
import currencySymbols from "../assets/CurrencySymbol/currencySymbol.json";
import ENVIRONMENT from "../config/ENVIRONMENT";

// @ts-ignore
export const globalAny: GlobalType = global;
export const VERSION = "2.0.21";
export const LANGUAGE_JSON_STORAGE_KEY = "languageJson";
export const MOVIES_TO_REDEEM_PLAYLIST = "0FE9E615-80A7-4994-B1BF-01677F45B5C7";
export const UNLIMITED_STREAMING_PLAYLIST = "90536013-D879-4187-8E0D-7D4017D1F9F8";
export const MY_LIBRARY_PLAYLIST = "my-library";
export const STUDIO_ACCESS_PLAYLIST = "studio-two";
export const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/;

/**
 * Logs the user in and calls back entered functions
 * @param email
 * @param password
 * @param setIsLoggedIn - sets a boolean signifying whether the user is logged in
 * @param onFatalLoginErrorFunc - function to perform when credentials are invalid
 * @param onNetworkDisconnectErrorFunc - function to perform when the app is disconnected
 */
export const login = async (
	email: string,
	password: string,
	setIsLoggedIn: any,
	onCredentialsIncorrectErrorFunc?: any,
	onNetworkDisconnectErrorFunc?: any
) => {
	let loginResponse: any;
	try {
		loginResponse = await loginApi(email, password);
		if (loginResponse) {
			return { proceed: loginResponse.session, response: loginResponse };
		}
	} catch (err) {
		if (err === INVALID_PROFILENAME_OR_PASSWORD || err == INVALID_PASSWORD || err == INVALID_PROFILENAME) {
			await clearLoginFromStorage();
			if (setIsLoggedIn) setIsLoggedIn(false);
			if (onCredentialsIncorrectErrorFunc) onCredentialsIncorrectErrorFunc();
		} else {
			if (onNetworkDisconnectErrorFunc) onNetworkDisconnectErrorFunc();
			AppLogger.log("Failed to log in (" + err + ")");
		}
	}
};

export const codeLogin = async (shortCode: any, setIsLoggedIn: any, onCredentialsIncorrectErrorFunc?: any, onNetworkDisconnectErrorFunc?: any) => {
	let loginResponse: any;
	try {
		loginResponse = await codeLoginApi(shortCode);
		if (loginResponse) {
			return { proceed: loginResponse.session, response: loginResponse };
		}
	} catch (err) {
		if (err == INVALID_SHORTCODE) {
			await clearLoginFromStorage();
			if (setIsLoggedIn) setIsLoggedIn(false);
			if (onCredentialsIncorrectErrorFunc) onCredentialsIncorrectErrorFunc();
		} else {
			if (onNetworkDisconnectErrorFunc) onNetworkDisconnectErrorFunc();
			AppLogger.log("Failed to log in (" + err + ")");
		}
	}
};

/**
 * Clears login info from storage
 */
export const clearLoginFromStorage = async () => {
	await AsyncStorage.removeItem(AsyncStorageKeys.email);
	await AsyncStorage.removeItem(AsyncStorageKeys.session);
	await AsyncStorage.removeItem(AsyncStorageKeys.consumerId);
	return true;
};

/**
 * Checks for duplicate devices in list of devices
 * @param arr
 * @return boolean
 */
export const isDuplicateDevice = (arr: Device[], deviceName: string) => {
	if (arr.find((device) => device.device == deviceName)) return true;
	return false;
};

/**
 * converts 00:00:00 to 00h00m
 * @param time
 * @returns {string}
 */
export const getDuration = (time: string) => {
	let timeArray = time.split(":");
	let hourArray = timeArray[0].split("");
	// if theres no hours (0,0) then dont show 0 hours
	if (hourArray[0] === "0" && hourArray[1] === "0") return `${timeArray[1]}m`;
	// if theres 9 or less hours, only show 9, not 09
	if (hourArray[0] === "0") return `${hourArray[1]}h ${timeArray[1]}m`;
	// if they pass back metadata with no : for minutes, then just treat the
	// two characters as an hour and a minute
	if (timeArray[0] !== "00") {
		return `${timeArray[0]}h ${timeArray[1]}m`;
	}
	// default to just showing minutes
	return `${timeArray[1]}m`;
};
/**
 * Gets the dynamic font size based on an original 1920 dp screen
 */
export const calculateFontSize = (fontSize: number) => {
	const windowWidth = Dimensions.get("window").width;
	return (windowWidth / 1920) * fontSize;
};

/**
 * Gets the dynamic width for different screen sizes. Makes a wider range of screens sizes look good.
 */
export const calculateDynamicWidth = (size: number) => {
	const windowWidth = Dimensions.get("window").width;
	return (windowWidth / 1920) * size;
};

/**
 * Calculate image width based on window width
 * @param isLandscape check if image is landscape (Packshot)
 * @param isBanner check if image is for banner (HeroBanner)
 * @returns
 */
export const calculateImageWidth = (isLandscape: any, isBanner: boolean) => {
	const windowWidth = Dimensions.get("window").width;
	const clamp = (number: number, min: number, max: number) => Math.max(min, Math.min(number, max));

	if (isBanner) return clamp(Math.round(windowWidth * 0.8), 800, 1300);

	return Math.round(isLandscape ? clamp(windowWidth * 0.6, 200, 600) : clamp(windowWidth * 0.3, 100, 300));
};

const generateIDwithCustomerZone = () => {
	let id = uuidv4();

	switch (localStorage.getItem(AsyncStorageKeys.profileName)) {
		case ProfileName.FrontScreen:
			return `0000${id}`;
		case ProfileName.PassengerScreen:
			return `0B${id}`;
		case ProfileName.LeftScreen:
			return `1A${id}`;
		case ProfileName.RightScreen:
			return `1B${id}`;
		default:
			return id;
	}
};

export const generateDeviceInfo = (profileName: any, deviceId?: any) => {
	const deviceID = deviceId ?? localStorage.getItem(AsyncStorageKeys.deviceId);
	const id = deviceID ?? generateIDwithCustomerZone();

	localStorage.setItem(AsyncStorageKeys.deviceId, id);

	return {
		deviceId: id,
		screenId: id,
		deviceName: profileName,
		screenName: profileName,
	};
};

export const generatePlayContent = (content: any, devices: any, masterDevice: any) => {
	return {
		allDevices: false,
		contentId: parseInt(content.contentId),
		deviceId: masterDevice.deviceId,
		devices,
		masterDevice,
		playSessionId: content.playSessionId,
		position: content.position,
		screenId: masterDevice.screenId,
		vamId: parseInt(content.vamId) ?? 0,
	};
};

export const getDeviceInfo = () => {
	const profileName = localStorage.getItem(AsyncStorageKeys.profileName);
	const deviceId = localStorage.getItem(AsyncStorageKeys.deviceId);

	return generateDeviceInfo(profileName, deviceId);
};

/**
 * Gets the dynamic height for different screen sizes. Makes a wider range of screens sizes look good.
 */
export const calculateDynamicHeight = (height: number, width: number) => {
	return (height / width) * calculateDynamicWidth(width);
};

/**
 * Turns an enum into an array. Iterating over the enumerator directly was not working reliably when I tried to use it for subscribing to topics in App.tsx
 * @param enumerator
 * @constructor
 */
export const EnumToArray = (enumerator: { [x: string]: any }) => {
	return Object.keys(enumerator).map((key) => enumerator[key]);
};

/**
 * Capitalize Word
 */
export const capitalize = (text: any) => {
	const arr = text.split(" ");
	const newText: any[] = [];
	arr.forEach((e: any) => {
		e = e.charAt(0).toUpperCase() + e.slice(1);
		newText.push(e);
	});

	return newText.join(" ");
};

/**
 * When selecting a tab, navigate to that page
 * @param tab
 */
export const getTabString = (tab: string) => {
	switch (tab) {
		case Tabs.home:
			return globalAny.language.header_home;
		case Tabs.screenManager:
			return globalAny.language.header_screen_manager;
		case Tabs.tripAssist:
			return globalAny.language.header_trip_assist;
		case Tabs.myLibrary:
			return globalAny.language.header_my_library;
		case Tabs.settings:
			return globalAny.language.header_settings;
	}
};

/**
 * Function for dynamically displaying the genres
 */
export const displayGenres = (metadata: any) => {
	let genresString = "";
	// @ts-ignore
	if (metadata) {
		// @ts-ignore
		if (metadata.genres) {
			// @ts-ignore
			metadata.genres.forEach((genreObj, index) => {
				genresString = genresString + genreObj.genre;
				// @ts-ignore
				genresString += index == metadata.genres.length - 1 ? "" : ", ";
			});
		}
	}
	return genresString;
};

/**
 * Returns the name of the director, or null if there is none
 */
export const getDirector = (metadata: any) => {
	if (metadata.crewMembers) {
		let director = metadata.crewMembers.filter((member: { role: string }) => member.role === "Director")[0];
		if (director) {
			return director.name;
		}
	}
	return null;
};

/**
 * Function for dynamically displaying the cast members
 */
export const displayCastMembers = (metadata: any) => {
	let castMembersString = "";
	// @ts-ignore
	if (metadata) {
		// @ts-ignore
		if (metadata.castMembers) {
			// @ts-ignore
			metadata.castMembers.forEach((castMemberObj, index) => {
				const { name } = castMemberObj;
				castMembersString = castMembersString + name;
				// @ts-ignore
				castMembersString += index == metadata.castMembers.length - 1 ? "" : ", ";
			});
		}
	}
	return castMembersString;
};

/**
 * Language map
 */
export const languagesMap = {
	AR: "عربي",
	CA: "Español",
	CN: "繁體中文",
	CS: "čeština",
	DA: "Dansk",
	DE: "Deutsch",
	EL: "Ελληνικά",
	EN: "English",
	ES: "Español (LAM)",
	FI: "Finnois",
	"FR-CAACL": "français - Canada",
	FR: "Français",
	HE: "עִברִית",
	HI: "हिन्दी",
	HU: "Magyar",
	ID: "Bahasa Indonesia",
	IT: "Italiano",
	JA: "日本語",
	KO: "한국어",
	MS: "Melayu",
	NL: "Nederlands",
	NO: "Norsk",
	PL: "Polski",
	PP: "Português (BR)",
	PT: "Português",
	RU: "Русский",
	SK: "Slovenčina",
	SV: "Svenska",
	TH: "ไทย",
	TR: "Türkçe",
	UK: "український",
	VI: "tiếng Việt",
	ZH: "简体中文",
};

/**
 * Retrieves the language from the given language code
 * @param languageMapCode - language code from languagesMap
 */
//@ts-ignore
export const getLanguage = (languageMapCode) => {
	const langArray = Object.keys(languagesMap);
	let index = 0;
	let found = false;
	let returnedLanguage = null;
	while (!found && index < langArray.length) {
		if (languageMapCode.toUpperCase().startsWith(langArray[index])) {
			//@ts-ignore
			returnedLanguage = languagesMap[langArray[index]];
			found = true;
		}
		index++;
	}
	// if we didnt find the code as supported, we should just show languageMapCode
	if (returnedLanguage == null) {
		return languageMapCode;
	}
	return returnedLanguage;
};

/**
 * Logs the user out asynchronously and then reloads the window
 */
export const logout = async () => {
	await logoutDevice();
	const isInCarScreen = localStorage.getItem(AsyncStorageKeys.isInCarScreen);
	const criteria =
		isInCarScreen == "true"
			? [
					AsyncStorageKeys.carActivity,
					AsyncStorageKeys.floatingButton,
					AsyncStorageKeys.LanguageOverride,
					AsyncStorageKeys.RegionOverride,
					AsyncStorageKeys.languageJson,
					AsyncStorageKeys.wheelPosition,
					AsyncStorageKeys.profileName,
					AsyncStorageKeys.isInCarScreen,
					AsyncStorageKeys.hwid,
					AsyncStorageKeys.deviceIdentifier,
					AsyncStorageKeys.masteruser,
					AsyncStorageKeys.dataExchange,
					AsyncStorageKeys.deviceId,
					AsyncStorageKeys.customerZone,
			  ]
			: [
					AsyncStorageKeys.carActivity,
					AsyncStorageKeys.floatingButton,
					AsyncStorageKeys.LanguageOverride,
					AsyncStorageKeys.RegionOverride,
					AsyncStorageKeys.languageJson,
					AsyncStorageKeys.wheelPosition,
			  ];
	const keys = (await AsyncStorage.getAllKeys()).filter((value: any) => !criteria.includes(value));
	await AsyncStorage.multiRemove(keys).then(() => {
		window.location.replace("/landing");
	});
};

/**
 * gets the best image based on the metadatas packshots
 * @param packshots - array of images
 * @param isLandscape - if we want only landscape images
 * @param closestWidthAtLeastEqualTo - width we want to be at least and closest to
 * @return {Packshot | null}
 */
export const bestImage = (packshots: Array<Packshot>, isLandscape: boolean, closestWidthAtLeastEqualTo: number) => {
	if (Object.keys(packshots).length > 0) {
		let bestCandidate: Packshot | undefined;
		packshots.forEach((item: Packshot) => {
			if (
				bestCandidate == null ||
				(item.isLandscape === isLandscape && item.width < bestCandidate.width && item.width >= closestWidthAtLeastEqualTo)
			) {
				bestCandidate = item;
			}
		});
		return bestCandidate;
	}
};
/**
 * Goal: get the playlist based on either a category object or a playlistId. If playlistId is provided, don't make the unneeded category fetch.
 * Returns a products array
 */
export const getPlaylistByCategory = async (categoryName: any, playlistId?: string) => {
	try {
		// 1. You have the playlistId
		if (playlistId) {
			const playlist: any = await getPlaylist(playlistId.toString(), false);
			return playlist.products;
		}
		// 2. You only have the category title
		else {
			let categories: any = await getCategories("porsche-demo", getTransactionFilters());
			for (const cat of categories) {
				let playlist: any = await getPlaylist(cat.playlistId ?? cat.customListId, cat.customListId != null);
				if (categoryName == cat.name) return playlist.products;
			}
		}
	} catch (err) {
		// failed to retrieve playlist
		AppLogger.log(err);
	}
};

export const pushMainAppStateToBrowserHistory = (state: string) => {
	window.history.pushState({ mainAppState: state }, state, `#${state}`);
};

export const replaceMainAppStateInBrowserHistory = (state: string) => {
	window.history.replaceState({ mainAppState: state }, state, `#${state}`);
};

export const pushLoginStateToBrowserHistory = (state: string) => {
	window.history.pushState({ loginState: state }, state, `#${state}`);
};

export const replaceLoginStateInBrowserHistory = (state: string) => {
	window.history.replaceState({ loginState: state }, state, `#${state}`);
};

export const pushProfileNameStateToBrowserHistory = (state: string) => {
	window.history.pushState({ profileNameState: state }, state, `#${state}`);
};

export const replaceProfileNameStateInBrowserHistory = (state: string) => {
	window.history.replaceState({ profileNameState: state }, state, `#${state}`);
};

export const pushCreateAccountStateToBrowserHistory = (state: string) => {
	window.history.pushState({ createAccountState: state }, state, `#${state}`);
};

export const pushRecoverPasswordStateToBrowserHistory = (state: string) => {
	window.history.pushState({ recoverPassword: state }, state, `#${state}`);
};

export const pushSignInStateToBrowserHistory = (state: string) => {
	window.history.pushState({ signInState: state }, state, `#${state}`);
};

export const replaceRecoverPasswordStateToBrowserHistory = (state: string) => {
	window.history.replaceState({ recoverPassword: state }, state, `#${state}`);
};

export const replaceCreateAccountStateInBrowserHistory = (state: string) => {
	window.history.replaceState({ createAccountState: state }, state, `#${state}`);
};

/**
 * When user clicks a product, open the modal for it
 * @param parentProductId
 */
export const selectProduct = (parentProductId: number, navigationTitleScreen: TitleDetailsScreenNavigationProp) => {
	// Directions pop up if user clicks the display image
	if (parentProductId == 0) {
		alert(globalAny.language.swipe_to_see_featured);
		return;
	}
	// Go to title details if it is a valid movie
	pushMainAppStateToBrowserHistory(MainAppState.titleDetails);
	navigationTitleScreen.navigate(routeTitleDetails, { parentProductId: parentProductId });
};

/**
 * Adds any keys that aren't set by the server to show the key
 * So its easy to determine which key is not supported yet on json
 */
export const addNewJSONStringsAsKeysIfNeeded = () => {
	for (let key in EN.Strings) {
		if (globalAny.language[key] == undefined) {
			globalAny.language[key] = key;
		}
	}
};

/**
 * Reconnect to network
 * @param client
 * @param setShowNetworkConnectionErrorModal
 * @param navigate - function that navigates user back to the page we want them to land on
 */
export const reconnectToNetwork = (client: Client | undefined, setShowNetworkConnectionErrorModal: any, navigate: any) => {
	setShowNetworkConnectionErrorModal(false);
	// delays one second so the loading indicator shows briefly even if it just sends them back to this error pop up.
	// Makes for cleaner User Experience.
	setTimeout(navigate, 1000); // is using navigation a good method here?
};

/**
 * Query string determines if we are on uat, staging, or prod
 * @returns {string}
 */
export const getServerType = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	return urlParams.get("serverType");
};

/**
 * Calls getMetadata and resolves with an object with the data needed to render a playback button
 * @param channel
 * @param client
 * @param index
 * @returns a promise that resolves with an object representing a PlayBackChannelButton or rejects with an error
 */
export const getPlaybackButton = (channel: ActivePlaybackChannel, index: number) => {
	let promise = new Promise((resolve, reject) => {
		getMetadata(channel.parentProductId)
			.then((response) =>
				resolve({
					channelNumber: index,
					//@ts-ignore
					title: response.title,
					activeDevices: channel.devices,
					//@ts-ignore
					packshots: response.packshots,
					parentProductId: channel.parentProductId,
					mqttCommand: channel.mqttCommand,
				})
			)
			.catch((err) => reject(err));
	});

	return promise;
};

export const getChannel = (channel: ActivePlaybackChannel, index: number) => {
	return getPlaybackButton(channel, index);
};

/**
 * Gets the name of the last (latest) route in the navigation history
 * @param navigationProp
 * @returns the name of the last route or null
 */
export const getLastRouteName = (navigationProp: NativeStackNavigationProp<any>) => {
	const navStateHistory = navigationProp.getState().routes;
	// ensures there is a route history
	if (navStateHistory != null && navStateHistory.length > 0) {
		// returns the name for the last (latest) route in the routes array
		return navStateHistory[navStateHistory.length - 1].name;
	}
	// otherwise returns null
	return null;
};

/**
 *
 * @returns true if the string is null or empty
 */
export const isEmpty = (str: string) => {
	return !str || str.length === 0;
};

/**
 * Gives the hex value of an entered character
 * @param char
 * @returns the hex value of the the entered character
 */
export const hexValueConverter = (char: string) => {
	let hexValueOf_a = 10;
	let asciiValueOf_a = 97;

	if (RegExp(/[a-z]/i).exec(char)) {
		char = char.toLowerCase();
		return char.charCodeAt(0) - asciiValueOf_a + hexValueOf_a;
	} else {
		return parseInt(char);
	}
};
// Validate Emails
export const validateEmail = (text: string) => {
	let reg =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (reg.test(text) === false) {
		return false;
	}

	return true;
};

export const validateInputVoucher = (text: string) => {
	let reg = /[^a-zA-Z0-9_-]/;
	if (reg.test(text) !== false || !isNaN(+text.replace(" - ", ""))) {
		return false;
	}

	return true;
};

export const validateDateFormat = (text: string) => {
	if (isNaN(+text.replace(" / ", ""))) {
		return false;
	}

	return true;
};

export const getItem = (data: any) => {
	if (!data) return [];

	const newData = data?.map((item: any) => {
		if (item?.isHeroBanner === true) return item;
		const productData = item?.products?.map((obj: any) => {
			if (item.customListId === null && !item?.isBanner) {
				let packshots: any;
				let backupPackshot: any;

				if (item?.isLandscape) {
					packshots = obj?.packshots?.filter((item: any) => item?.isLandscape === true);
					[backupPackshot] = obj?.packshots?.filter((item: any) => item?.width === 300) ?? {};
				}

				if (!item?.isLandscape && !item?.isBanner) {
					packshots = obj?.packshots?.filter((item: any) => item?.width === 300);
				}

				return { parentProductId: obj?.parentProductId, packshots: packshots[0]?.url, backupPackshot: backupPackshot?.url };
			} else {
				if (item?.isBanner) return { parentProductId: obj?.parentProductId, packshots: item?.categoryImages[0]?.imageUrl };

				return { parentProductId: obj?.id, packshots: obj?.imageUrl };
			}
		});

		return {
			...item,
			vamProducts: item.products,
			products: productData,
			isProgressBar: item?.rawTitle?.includes("Continue Watching"),
			title: item?.title,
		};
	});

	return newData?.filter((val: any) => val?.categoryName !== "Hero Banners");
};

export const getPackshot = (data: any) => {
	const newData = data?.map((item: any) => {
		const productData = item?.packshots?.filter((item: any) => item?.width === 600 && item?.isLandscape === false);
		return { parentProductId: item?.parentProductId, packshots: productData[0]?.url, title: item?.title };
	});

	return newData;
};

export const streamProgress = (currentTime: number, duration: number) => {
	const result = currentTime / duration;

	return result;
};
export const formatVoucherCode = (voucherCode: string) => {
	const alphanumericVal = voucherCode.replace(/[^a-zA-Z0-9]/g, "");

	let formattedVoucher = "";
	for (let i = 0; i < alphanumericVal.length; i++) {
		if (i === 4) {
			formattedVoucher += "-";
		}
		formattedVoucher += alphanumericVal[i];
	}

	return formattedVoucher;
};

export const validateVoucherTextInput = (convertVal: string) => {
	return formatVoucherCode(convertVal);
};

/**
 * Convert duration format to seconds
 * @param duration - string format hh:mm:ss
 * @returns - returns seconds
 */
export const convertDuration = (duration: string) => {
	if (!duration) return 1;

	const hhmmss = duration.split(":");
	if (hhmmss.length == 3) {
		return +hhmmss[0] * 60 * 60 + +hhmmss[1] * 60 + +hhmmss[2];
	} else {
		return +hhmmss[0] * 60 * 60 + +hhmmss[1] * 60;
	}
};

/**
 * Return datetime one hour form now.
 * @returns datetime
 */
export const getDateTimePlusHour = () => {
	const now = new Date();

	const additionalHour = now.getHours() + 1;

	return now.setHours(additionalHour);
};

/**
 * Use to add | separator
 * @returns string with separator
 */
export const metadataTextSeparator = (metadata: any) => {
	const year = metadata?.year;
	const rating = metadata?.ratingReason;
	const duration = timeFormat(metadata?.duration);
	const certNumber = metadata?.certificateNumber === null ? "" : `${metadata?.certificateNumber}`;

	if (!rating && !certNumber) return `${year}  |  ${duration}`;
	if (!rating) return `${year}  |  ${certNumber}  |  ${duration}`;

	return `${year}  |  ${rating} ${certNumber}  |  ${duration}`;
};

/**
 * Convert string to time format
 * @returns string
 */
const timeFormat = (val: any) => {
	if (val) {
		const time = val.split(":");
		const hr = time[0].replace("0", "") + "h";
		const m = time[1] === "00" ? "0m" : time[1].replace(/^0+/, "") + "m";
		return hr + " " + m;
	}
	return val;
};

const formatCurrency = (amount: number | bigint, currencyCode: any, regionOverride = "US", languageOverride = "en") => {
	try {
		const symbol = getCurrencySymbol(currencyCode);
		return `${symbol}${amount}`;
	} catch (error) {
		AppLogger.log(error);
	}
};

const formatTransactionPrefix = (item: any, regionOverride: string, languageOverride: string) => {
	if (item.currency !== "N/A" && item.currency !== null) return formatCurrency(item.value, item.currency, regionOverride, languageOverride);
	return `${item.value} ${globalAny.language.credit}`;
};

/**
 * Convert value into transactional history format
 * @returns string
 */
export const historyFormat = (item: any) => {
	const regionOverride = localStorage.getItem("RegionOverride") ?? "US";
	const languageOverride = localStorage.getItem("LanguageOverride") ?? "en";
	const filteredStatus = [PurchaseTransactionType.PaymentDeclined, PurchaseTransactionType.TaxRefundPending, PurchaseTransactionType.TaxRefunded];

	const result = item
		.filter((obj: any) => !filteredStatus.includes(obj.status))
		.map((val: any) => {
			let type: any;

			if (val?.transactionTypeId === PurchaseTransactionType.Purchase) {
				type = val.currency !== "N/A" && val.currency !== null ? globalAny.language.purchased_on : globalAny.language.redeem_on;
			} else if (val?.transactionTypeId === PurchaseTransactionType.Rent) {
				type = globalAny.language.rented_on;
			}

			if (val?.status === PurchaseTransactionType.Refund || val?.status === PurchaseTransactionType.RefundPending) {
				type = globalAny.language.refunded_on;
			}

			const details = `${type} ${formatDate(new Date(val?.transactionDate))} ${globalAny.language.for} ${formatTransactionPrefix(
				val,
				regionOverride,
				languageOverride
			)}`;

			const transaction: TransactionHistory = {
				title: val?.localTitle ?? val?.title,
				details: details,
				transactionId: null,
				parentProductId: val.itemId,
				currency: val?.currency,
				value: val?.value,
				transactionTypeId: val?.transactionTypeId,
			};

			return transaction;
		});

	return result;
};

export const padTo2Digits = (num: any) => num.toString().padStart(2, "0");

export const formatDate = (date: Date) => [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join("/");

export const formatHoursAndMinutesFromToday = (date: any) => {
	const currentExpiryDate: any = new Date(date);
	const today: any = new Date(new Date().toUTCString());
	const totalTime = Math.abs(currentExpiryDate.getTime() - today.getTime());
	const totalHours: any = totalTime / 3600000;
	const totalRHours: any = Math.floor(totalTime / 3600000);
	const totalMinutes: any = Math.abs((totalHours - totalRHours) * 60);

	return {
		hours: Math.round(totalHours).toString(),
		minutes: Math.round(totalMinutes).toString(),
	};
};

/**
 * Convert into unique channel
 * @returns object
 */
export const uniqueChannel = (item: any) => {
	const uniqueIds: any[] = [];
	const uniqueData = item
		?.sort((a: any, b: any) => a?.screenName?.localeCompare(b?.screenName))
		.filter((val: any) => {
			const isDuplicate = uniqueIds.includes(val?.screenName);

			if (!isDuplicate) {
				uniqueIds.push(val?.screenName);
				return true;
			}

			return false;
		});

	return uniqueData.filter((channel: any) => channel.screenName != null);
};

/**
 * Convert into unique channel
 * @returns object
 */
export const uniqueChannelData = (item: any) => {
	const uniqueIds: any[] = [];
	const uniqueChannel: any[] = [];
	item?.sort((a: any, b: any) => a?.id?.localeCompare(b?.id)).map((val: any) => {
		const isDuplicate = uniqueIds.indexOf(val?.id);

		if (isDuplicate === -1) {
			uniqueIds.push(val?.id);
			uniqueChannel.push(val);
		} else {
			uniqueChannel[isDuplicate] = val;
		}
	});

	return uniqueChannel.filter((channel: any) => channel.id != null);
};

/**
 * Checking networking connection using APIKEY.
 * @returns true or false
 */
export const onCheckingNetwork = async () => {
	try {
		const region = await AsyncStorage.getItem(AsyncStorageKeys.RegionOverride);
		const isResponse: any = await checkApiKey(region);
		return isResponse.responseCode === 10000;
	} catch {
		return false;
	}
};

/**
 * generate Security Code Placeholder
 * @returns string
 */
export const generateSecurityCodePlaceholder = (number: number) => {
	let i = 0;
	let placeholder = "";
	while (i < number) {
		placeholder += "0";
		i++;
	}
	return placeholder;
};

export const filterUniqueDevice = (value: any, index: any, array: any) => {
	return array.findIndex((item: any) => item.screenId === value.screenId) === index;
};

export const validateCustomerZone = (customerzone: any) => {
	localStorage.setItem(AsyncStorageKeys.customerZone, customerzone);
	switch (customerzone) {
		case "0A":
			localStorage.setItem(AsyncStorageKeys.deviceId, `0000${localStorage.getItem(AsyncStorageKeys.deviceId)}`);
			localStorage.setItem(AsyncStorageKeys.profileName, ProfileName.FrontScreen);
			localStorage.setItem(AsyncStorageKeys.isInCarScreen, "true");
			break;
		case "0B":
			localStorage.setItem(AsyncStorageKeys.profileName, ProfileName.PassengerScreen);
			localStorage.setItem(AsyncStorageKeys.isInCarScreen, "true");
			break;
		case "1A":
			localStorage.setItem(AsyncStorageKeys.profileName, ProfileName.LeftScreen);
			localStorage.setItem(AsyncStorageKeys.isInCarScreen, "true");
			break;
		case "1B":
			localStorage.setItem(AsyncStorageKeys.profileName, ProfileName.RightScreen);
			localStorage.setItem(AsyncStorageKeys.isInCarScreen, "true");
			break;
		default:
			localStorage.setItem(AsyncStorageKeys.isInCarScreen, "false");
			localStorage.removeItem(AsyncStorageKeys.customerZone);
			break;
	}
};

export const getDateTimePlusMinute = () => {
	return new Date().setMinutes(new Date().getMinutes() + 1);
};

export const generateGroupId = () => {
	let groupDevices = JSON.parse(localStorage.getItem(AsyncStorageKeys.watchGroup) ?? "[]");
	let defaultGroupId = 1;
	let groupId;

	if (!groupDevices.length) return defaultGroupId;

	while (!groupId) {
		const groupIds = groupDevices.map((device: any) => device.group);

		if (!groupIds.find((id: any) => id === defaultGroupId.toString())) {
			groupId = defaultGroupId;
		} else {
			defaultGroupId++;
		}
	}
	return groupId.toString();
};

export const getGroupId = (playSessionId: string, deviceId: any) => {
	if (!playSessionId) return;

	let watchGroups = JSON.parse(localStorage.getItem(AsyncStorageKeys.watchGroup) ?? "[]");

	if (watchGroups.length) {
		const watchGroupIndex = watchGroups
			.sort(sortGroupByCreated)
			.findIndex(
				(group: any) =>
					group.playSessionId === playSessionId &&
					group.devices.find((device: any) => device.screenId === deviceId) &&
					group.devices.length > 1
			);
		return watchGroupIndex + 1;
	}

	return 0;
};

export const getVideoQualityByDeviceHeight = (height: number) => {
	AppLogger.log("[PLAYBACK] screenHeight for video quality", height);

	if (localStorage.getItem(AsyncStorageKeys.profileName) === ProfileName.PassengerScreen) return 720;

	return 1080;
};

export const sortDevices = (deviceA: any, deviceB: any) => {
	if (deviceA.screenId < deviceB.screenId) {
		return -1;
	}
	if (deviceA.screenId > deviceB.screenId) {
		return 1;
	}
	return 0;
};

export const sortGroupByCreated = (deviceA: any, deviceB: any) => {
	if (deviceA.created < deviceB.created) {
		return -1;
	}
	if (deviceA.created > deviceB.created) {
		return 1;
	}
	return 0;
};

export const inWatchGroup = (mqttPlayContent: any, currentDevice: any) => {
	const watchGroup = JSON.parse(localStorage.getItem(AsyncStorageKeys.watchGroup) ?? "[]");
	const group = watchGroup.find((group: any) => group.playSessionId === mqttPlayContent?.playSessionId && group.devices.length > 1);

	AppLogger.log("inWatchGroup", group);
	if (!group) {
		localStorage.setItem(AsyncStorageKeys.inWatchGroup, "false");
		return false;
	}

	localStorage.setItem(AsyncStorageKeys.inWatchGroup, "true");
	return true;
};

export const isMaster = (mqttPlayContent: any, currentDevice: any) => {
	const watchGroup = JSON.parse(localStorage.getItem(AsyncStorageKeys.watchGroup) ?? "[]");
	const group = watchGroup.find((group: any) => group.playSessionId === mqttPlayContent.playSessionId);
	let masterDevice;

	if (!group) return;

	[masterDevice] = group.devices;

	return masterDevice.screenId === currentDevice.screenId;
};

export const getLanguageName = (languageCode: string) => {
	const appWording = localStorage.getItem(AsyncStorageKeys.languageJson);
	if (!appWording || !languageCode) return;
	const parsedLanguages = JSON.parse(appWording);
	const [language] = languageCode.split("-");
	const languageKeyDefault = `LanguageName_${languageCode}`;
	const languageKeyFull = `LanguageName_${languageCode.toLocaleUpperCase()}`;
	const languageKey = `LanguageName_${language.toLocaleUpperCase()}`;
	return parsedLanguages[languageKeyFull] ?? parsedLanguages[languageKeyDefault] ?? parsedLanguages[languageKey];
};

export const convertToLanguage = (language: any) => {
	const convertedLang = language
		.split(",")
		.map((lang: string) => getLanguageName(lang.trim()))
		.join(", ");
	return convertedLang;
};

export const endProgress = async (transactionType: number) => {
	const trackingId = localStorage.getItem(AsyncStorageKeys.trackingId);
	const lastStreamProgress: any = localStorage.getItem(AsyncStorageKeys.streamProgress);
	const totalDuration: any = localStorage.getItem(AsyncStorageKeys.totalDuration);
	const lastParentProductId: any = localStorage.getItem(AsyncStorageKeys.parentProductId);
	const hasPreviousPlaybackSession = trackingId !== "undefined" && trackingId && lastParentProductId !== "undefined" && lastParentProductId;

	if (hasPreviousPlaybackSession) {
		await setProgressVideo(
			parseInt(lastParentProductId),
			Math.round(parseInt(lastStreamProgress)) || 0,
			1,
			Math.round(parseInt(totalDuration)) || 0,
			ProgressEvents.end,
			trackingId ?? "",
			transactionType ?? 1
		);
	}
};

export const isInCarScreen = () => localStorage.getItem(AsyncStorageKeys.isInCarScreen) == "true";

export const processToastMessage = (automaticVoucherCode: any) => {
	const code = automaticVoucherCode?.code;
	const credits = automaticVoucherCode?.credits;
	const creditsMessage = `${credits} ${globalAny.language.redeem_title_of_choice}`;

	let message = code ? `${code}\n` : "";
	message += `${globalAny.language.your_voucher}`;
	if (credits) message += `\n${creditsMessage}`;

	return message;
};

export const getTranslatedDeviceName = (deviceName: string) => {
	switch (deviceName) {
		case ProfileName.FrontScreen:
			return globalAny.language.in_car;
		case ProfileName.PassengerScreen:
			return globalAny.language.co_user;
		case ProfileName.LeftScreen:
			return globalAny.language.left_rear_user;
		case ProfileName.RightScreen:
			return globalAny.language.right_rear_user;
		default:
			return deviceName;
	}
};

export const isStaging = () =>
	(DEFAULT_ENV !== ENVIRONMENT.PRODUCTION && DEFAULT_ENV !== ENVIRONMENT.UAT) || localStorage.getItem(AsyncStorageKeys.enableMQTT) == "true";

export const isProduction = () => DEFAULT_ENV === ENVIRONMENT.PRODUCTION;

export const isCoPassenger = () => localStorage.getItem(AsyncStorageKeys.customerZone) === "0B";

export const getTransactionFilters = () => {
	let subscription = localStorage.getItem(AsyncStorageKeys.subscription);
	AppLogger.log("subscription", subscription);

	if (!subscription) {
		return "1,3,4";
	}

	if (!subscription.length || subscription === undefined || subscription === null || subscription === "null" || subscription === "undefined") {
		return "1,3,4";
	}

	return "1,2,3,4";
};

export const isSkipDataManagement = () => {
	let skippableRegions = ["DE", "FR"];
	const getOption0 = JSON.parse(localStorage.getItem(AsyncStorageKeys.acceptOtherDataSettings) ?? "{}");
	const legalOption0 = JSON.parse(getOption0?.data);
	const getOption1 = JSON.parse(localStorage.getItem(AsyncStorageKeys.acceptOtherDataSignUp) ?? "{}");
	const legalOption1 = JSON.parse(getOption1?.data);

	if (!legalOption0?.sections || !legalOption1.sections) {
		skippableRegions = [...skippableRegions, localStorage.getItem(AsyncStorageKeys.RegionOverride) ?? ""];
	}

	return skippableRegions.find((region) => region === localStorage.getItem(AsyncStorageKeys.RegionOverride));
};

export const getCurrencySymbol = (currencyCode: any) => {
	const symbol = currencySymbols.find((item: any) => item.code === currencyCode);
	return symbol?.symbol_native;
};

export const isParamsAvailable = () => {
	const customerzoneLocal = localStorage.getItem(AsyncStorageKeys.customerZone) ?? null;
	const masteruserLocal = localStorage.getItem(AsyncStorageKeys.masteruser) ?? null;
	const hwidLocal = localStorage.getItem(AsyncStorageKeys.hwid) ?? null;

	if (customerzoneLocal && masteruserLocal !== null && hwidLocal) return true;

	return false;
};

export const countryDataList = {
	control: [
		{ countryCode: "JP", noDecimal: true },
		{ countryCode: "KR", noDecimal: true },
	],
};
